<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header d-flex justify-content-between">
            <div class="card-title">
              <!-- <h3 class="card-label" v-html="mainData.name"></h3> -->
              <h3 class="card-label">
                {{ $t('MENU.REPORT.FINANCE_REPORT.BALANS') }}
              </h3>
            </div>
          </div>
          <!-- new card body -->
          <div class="card-body">
            <div class="card-button col-4 pl-0">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :label="nameRules"
                    append-icon="event"
                    readonly
                    :value="date | dateFilter"
                    @blur="getData"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn small color="error" @click="modal = false"
                    >Bekor qilish</v-btn
                  >
                  <v-btn small color="success" @click="$refs.dialog.save(date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </div>
            <div v-if="isLoading" class="text-center mt-5">
              <b-spinner
                style="width: 3rem; height: 3rem"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <div class="limiter" v-if="!isLoading">
              <div class="container-table100">
                <div class="wrap-table100">
                  <div class="table100">
                    <table>
                      <thead>
                        <tr class="table100-head">
                          <th class="column1">Курсаткичлар номи</th>
                          <th class="column2">Сатр коди</th>
                          <th class="column3">Хисобот даври охирига</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="balansdivisionsection_set in active.balansdivisionsection_set"
                        :key="balansdivisionsection_set.id"
                      >
                        <tr class="aktivPassiveTr">
                          <td
                            class="text-center"
                            colspan="3"
                            style="
                              background-color: rgb(219 228 255);
                              color: #000;
                            "
                          >
                            {{ active.name_ru }}
                          </td>
                        </tr>
                        <tr
                          v-for="balansaccount_set in balansdivisionsection_set.balansaccount_set"
                          :key="balansaccount_set.id"
                        >
                          <td>
                            {{ balansaccount_set.name }}
                          </td>
                          <td>
                            {{ balansaccount_set.code }}
                          </td>

                          <td>
                            {{ balansaccount_set.total | mask }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-dark">
                            <b> Баланс активи буйича жами </b>
                          </td>
                          <td></td>
                          <td class="text-dark">
                            <b>
                              {{
                                balansdivisionsection_set
                                  | activeCalcTotal
                                  | mask
                              }}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        class="passiveTd"
                        v-for="balansdivisionsection_set in passive.balansdivisionsection_set"
                        :key="balansdivisionsection_set.id"
                      >
                        <tr class="aktivPassiveTr passiveBg">
                          <td
                            class="text-center"
                            colspan="3"
                            style="
                              background-color: rgb(149 176 255);
                              color: #fff;
                            "
                          >
                            {{ passive.name_ru }}
                          </td>
                        </tr>
                        <tr
                          v-for="balansaccount_set in balansdivisionsection_set.balansaccount_set"
                          :key="balansaccount_set.id"
                        >
                          <td>
                            {{ balansaccount_set.name }}
                          </td>
                          <td>
                            {{ balansaccount_set.code }}
                          </td>
                          <td>
                            {{ balansaccount_set.total | mask }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-dark">Баланс пассиви буйича жами</td>
                          <td></td>
                          <td class="text-dark">
                            {{
                              balansdivisionsection_set
                                | passiveCalcTotal
                                | mask
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data: () => ({
    date: '',
    modal: false,
    activeColor: 'red'
  }),
  beforeCreate() {
    this.$store.dispatch('getFinanceBalance')
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t('MENU.REPORT.TITLE') },
        { title: this.$t('MENU.REPORT.FINANCE_REPORT.TITLE') },
        { title: this.$t('MENU.REPORT.FINANCE_REPORT.BALANS') }
      ])
    })
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('es-US')
      else return val
    },
    activeCalcTotal(list) {
      const total = list.balansaccount_set
        .filter((x) => x.code !== '0100' && x.code !== '0200')
        .map((x) => x.total)
        .reduce((a, b) => a + b, 0)
      return total
    },
    passiveCalcTotal(list) {
      const total = list.balansaccount_set
        .map((x) => x.total)
        .reduce((a, b) => a + b, 0)
      return total
    }
  },
  computed: {
    nameRules() {
      return this.$t('PAGES.DATE')
    },
    mainData() {
      const data = this.$store.state.requests.financeBalance
      return data
    },
    active() {
      var data = this.mainData.balansdivision_set
      if (data !== undefined)
        data = this.mainData.balansdivision_set.find((x) => x.tag == 'active')
      return data
    },
    passive() {
      var data = this.mainData.balansdivision_set
      data = this.mainData.balansdivision_set.find((x) => x.tag == 'passive')
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  methods: {
    colorChange: function (val) {
      document.getElementsByClassName(val)[0].classList.toggle('colorTr')
    }
  },
  watch: {
    date: function (newVal, oldval) {
      if (oldval !== newVal) {
        this.$store.dispatch('getFinanceBalance', newVal)
      }
    }
  }
}
</script>

<style scoped>
.colorTr {
  background-color: rgb(149, 176, 255);
  color: #fff;
}
.aktivPassiveTr td {
  height: 30px;
}
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-table100 {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.wrap-table100 {
  width: 100%;
}

table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
table * {
  position: relative;
}
/* table td,
table th {
  padding-left: 8px;
} */
table thead tr {
  height: 60px;
  background: #3699ff;
  font-weight: bold;
}
table tbody tr {
  height: 25px;
  border-bottom: 1px solid #f2f2f2;
}
table tbody tr:last-child {
  border: 0;
}
table td,
table th {
  text-align: left;
  padding: 0 10px;
}
table td.l,
table th.l {
  text-align: right;
}
table td.c,
table th.c {
  text-align: center;
}
table td.r,
table th.r {
  text-align: center;
}

.table100-head th {
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
}

/* tbody tr:nth-child(even) {
  background-color: #f5f5f5;
} */

tbody tr {
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  font-weight: bold;
}

tbody tr:hover {
  color: #333;
  font-weight: bold;
  background-color: rgb(219 228 255);
  cursor: pointer;
}
.passiveTd tr:hover {
  background-color: rgb(149, 176, 255);
  color: #fff;
}

/* .column1 {
  width: 260px;
  padding-left: 40px;
} */

/* .column2 {
  width: 160px;
}

.column3 {
  width: 245px;
}

.column4 {
  width: 110px;
  text-align: right;
}


.column5 {
  width: 170px;
  text-align: right;
} */

/* .column6 {
  width: 222px;
  text-align: right;
  padding-right: 62px;
} */
/* 
@media screen and (max-width: 992px) {
  table {
    display: block;
  }
  table > *,
  table tr,
  table td,
  table th {
    display: block;
  }
  table thead {
    display: none;
  }
  table tbody tr {
    height: auto;
    padding: 37px 0;
  }
  table tbody tr td {
    padding-left: 40% !important;
    margin-bottom: 24px;
  }
  table tbody tr td:last-child {
    margin-bottom: 0;
  }
  table tbody tr td:before {
    font-family: OpenSans-Regular;
    font-size: 14px;
    color: #999999;
    line-height: 1.2;
    font-weight: unset;
    position: absolute;
    width: 40%;
    left: 30px;
    top: 0;
  }
  table tbody tr td:nth-child(1):before {
    content: 'Date';
  }
  table tbody tr td:nth-child(2):before {
    content: 'Order ID';
  }
  table tbody tr td:nth-child(3):before {
    content: 'Name';
  }
  table tbody tr td:nth-child(4):before {
    content: 'Price';
  }
  table tbody tr td:nth-child(5):before {
    content: 'Quantity';
  }
  table tbody tr td:nth-child(6):before {
    content: 'Total';
  }

  .column4,
  .column5,
  .column6 {
    text-align: left;
  }

  .column4,
  .column5,
  .column6,
  .column1,
  .column2,
  .column3 {
    width: 100%;
  }

  tbody tr {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .container-table100 {
    padding-left: 15px;
    padding-right: 15px;
  }
} */
</style>
